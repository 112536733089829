/* You can add global styles to this file, and also import other style files */


body {
  transition: background 0.3s ease;
  //background-color: darken(#fff, 5%);
}

.navbar {
  transition: height 2s ease;
}

img.navbar-brand {
  max-width: 48px;
  max-height: 64px;
}

pre {
  line-height: 1em;
}

#playpausesvg {
  max-height: 2.5em;
  max-width: 5vw;
  padding: 0;
}

#playpausesvg > img {
  max-height: 2.5em;
}

.hide {
  transition: display 0.5s ease-out;
  display: none;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
